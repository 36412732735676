import React, { useMemo, useState, useCallback } from 'react';
import cx from 'clsx';
import Page from 'components/Page';
import RateForm from 'components/RateForm';
import { RateFormValues } from 'components/RateForm/RateForm';
import { Link } from 'react-router-dom';

import * as api from 'services/api';

import { ReactComponent as CloseIcon } from './assets/close.svg';

import './contact-page.scss';

const ContactPage: React.FC = () => {
  const [isPending, setPending] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const footerProps = useMemo(() => ({ action: null }), []);
  const handleSubmit = useCallback(async (values: RateFormValues) => {
    try {
      setPending(true);
      await api.postRequest(values);
      setSuccess(true);
    } finally {
      setPending(false);
    }
  }, []);

  return (
    <Page
      className={cx('contact-page', isSuccess && 'contact-page--success')}
      contentClassName="contact-page__content"
      backPath="/"
      title={isSuccess ? 'Thank you' : 'Rate my task'}
      footerProps={footerProps}
    >
      {!isSuccess ? (
        <RateForm
          className="contact-page__form"
          disabled={isPending}
          onSubmit={handleSubmit}
        />
      ) : (
        <div className="contact-page__message">
          We will
          <br />
          contact you
          <br />
          soon
          <Link className="contact-page__close" to="/">
            <CloseIcon />
          </Link>
        </div>
      )}
    </Page>
  );
};

export default ContactPage;
