export type RequestOutput<TData = any> = {
  response: Response;
  data: TData;
};

/**
 * Отправляет запрос на указанный URL.
 * @param url Путь запроса.
 * @param params Параметры запроса.
 */
export default async function request<T = any>(
  url: string,
  params: RequestInit = { method: 'GET' }
): Promise<RequestOutput<T>> {
  const response = await fetch(url, params);
  const data = await response.json().catch(() => null);
  return { response, data };
}
