import React from 'react';
import clsx from 'clsx';

import { ReactComponent as Light } from './assets/light.svg';
import { ReactComponent as Dark } from './assets/dark.svg';
import './logo.scss';

type Props = {
  theme?: 'light' | 'dark';
} & React.ComponentProps<typeof Light | typeof Dark>;

const Logo: React.FC<Props> = ({ className, ...restProps }) => {
  return (
    <>
      <Light className={clsx('logo logo--light', className)} {...restProps} />
      <Dark className={clsx('logo logo--dark', className)} {...restProps} />
    </>
  );
};

export default Logo;
