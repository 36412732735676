import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Logo from 'components/Logo/Logo';
import Contact from 'components/Contact';

import './footer.scss';

type Props = {
  action?: React.ReactNode;
} & React.ComponentProps<'footer'>;

const Footer: React.FC<Props> = ({ className, action, ...restProps }) => {
  return (
    <footer className={clsx('footer', className)} {...restProps}>
      <Link className="footer__logo" to="/">
        <Logo />
      </Link>
      {typeof action !== 'undefined' ? (
        action
      ) : (
        <Contact className="footer__contact" />
      )}
    </footer>
  );
};

export default Footer;
