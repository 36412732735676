import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { createAsyncAction } from 'typesafe-actions';
import * as api from 'services/api';
import StepDTO from 'types/StepDTO';

export const fetchStepsAsync = createAsyncAction(
  'FETCH_STEPS_REQUEST',
  'FETCH_STEPS_SUCCESS',
  'FETCH_STEPS_FAILURE'
)<void, StepDTO[], void>();

export const fetchSteps = (): ThunkAction<
  Promise<any>,
  {},
  {},
  AnyAction
> => async dispatch => {
  try {
    dispatch(fetchStepsAsync.request());
    const steps = await api.getSteps();
    dispatch(fetchStepsAsync.success(steps));
  } catch (error) {
    console.error(error);
    dispatch(fetchStepsAsync.failure());
  }
};
