import React, { useRef, useEffect, useState, createContext } from 'react';
import './loader.scss';

const text = 'We help startups';

export const LoaderContext = createContext<Boolean>(false);

const Loader: React.FC = ({ children }) => {
  const [ready, setReady] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const delay = 250;
    let timeout: NodeJS.Timeout;

    function update() {
      const loader = ref.current!;

      if (loader.textContent === text) {
        clearTimeout(timeout);
        loader.style.opacity = '0';
        setReady(true);
        return;
      }

      loader.textContent = text.slice(0, loader.textContent!.length + 1);
      timeout = setTimeout(update, delay);
    }

    update();

    return () => clearTimeout(timeout);
  }, []);

  return (
    <LoaderContext.Provider value={ready}>
      {!ready && <div ref={ref} className="loader" />}
      {children}
    </LoaderContext.Provider>
  );
};

export default Loader;
