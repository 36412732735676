import request from 'services/request';
import StepDTO from 'types/StepDTO';
import SubjectDTO from 'types/SubjectDTO';
import RetainerDTO from 'types/RetainerDTO';
import { RateFormValues } from 'components/RateForm/RateForm';
import StepCategoryDTO from 'types/StepCategoryDTO';
import CategoryDTO from 'types/CategoryDTO';
import CaseDTO from 'types/CaseDTO';
import SubjectCategoryDTO from 'types/SubjectCategoryDTO';

/**
 * Запрашивает шаги.
 */
export const getSteps = async () => {
  const { data } = await request<StepDTO[]>('/api/steps');
  return data;
};

/**
 * Запрашивает темы.
 */
export const getSubjects = async () => {
  const { data } = await request<SubjectDTO[]>('/api/subjects');
  return data;
};

/**
 * Запрашивает цены на услуги.
 * @param locale Язык.
 */
export const getRetainers = async (locale: string) => {
  const { data } = await request<RetainerDTO[]>(`/api/retainer/${locale}`);
  return data;
};

/**
 * Отправляет запрос на оценку.
 * @param form Форма.
 */
export const postRequest = async (form: RateFormValues) => {
  const { data } = await request<number>('/api/requests', {
    method: 'POST',
    body: JSON.stringify(form)
  });
  return data;
};

/**
 * Возвращает категории по шагам.
 * @param subjectId ID темы.
 */
export const getStepsCategories = async (subjectId: SubjectDTO['id']) => {
  const { data } = await request<StepCategoryDTO[]>(
    `/api/junction_steps_categories/${subjectId}`
  );

  return data;
};

/**
 * Запрашивает категорию.
 * @param categoryId ID категории.
 */
export const getCategory = async (categoryId: number) => {
  const { data } = await request<CategoryDTO | undefined>(
    `/api/categories/${categoryId}`
  );
  return data;
};

/**
 * Запрашивает кейсы.
 * @param categoryId ID категории.
 */
export const getCases = async (categoryId: number) => {
  const { data } = await request<CaseDTO[]>(
    `/api/junction_cases_categories/${categoryId}`
  );

  return data;
};

/**
 * Возвращает категории тем.
 * @param target Цель.
 */
export const getSubjectsCategories = async (target: string) => {
  const { data } = await request<SubjectCategoryDTO[]>(
    `/api/junction_categories_subjects/${target}`
  );
  return data;
};
