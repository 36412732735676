/**
 * Проверяет, что обязательное поле является заполненным.
 * @param value Значение поля.
 */
export const required = (value: any) => {
  if (typeof value === 'string') {
    return value.trim().length > 0 ? undefined : 'Please fill in the form.';
  }

  return value ? undefined : 'Please fill in the form.';
};
