import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import './retainer-button.scss';
import { ReactComponent as Icon } from './assets/icon.svg';

type Props = Omit<React.ComponentProps<typeof NavLink>, 'to'>;

const RetainerButton: React.FC<Props> = ({ className, ...restProps }) => (
  <NavLink
    to="/retainer"
    className={clsx('retainer-button', className)}
    activeClassName="retainer-button--active"
    {...restProps}
  >
    <Icon className="retainer-button__icon" />
    Retainer
  </NavLink>
);

export default RetainerButton;
