import React from 'react';
import clsx from 'clsx';
import './button.scss';

type Props = React.ComponentProps<'button'>;

const Button: React.FC<Props> = ({ className, ...restProps }) => (
  <button type="button" className={clsx('button', className)} {...restProps} />
);

export default Button;
