import { createReducer } from 'typesafe-actions';
import { SubjectsState, SubjectsAction } from './types';
import { fetchSubjectsAsync } from './actions';

export const initialState: SubjectsState = {};

export default createReducer<SubjectsState, SubjectsAction>(
  initialState
).handleAction(fetchSubjectsAsync.success, (state, { payload }) =>
  payload.reduce(
    (subjects, subject) => ({ ...subjects, [subject.id]: subject }),
    {}
  )
);
