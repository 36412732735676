import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Button from 'components/Button';

import './contact.scss';

type Props = React.ComponentProps<'div'>;

const Contact: React.FC<Props> = ({ className, ...restProps }) => {
  return (
    <div className={clsx('contact', className)} {...restProps}>
      <div className="contact__title">Got questions?</div>
      <Link to="/contact">
        <Button className="contact__button">Contact us</Button>
      </Link>
    </div>
  );
};

export default Contact;
