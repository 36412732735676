import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './store';
import AppGuard from './components/AppGuard';
import Loader from './components/Loader';
import AppRoutes from './AppRoutes';

const store = configureStore();

const App: React.FC = () => (
  <Provider store={store}>
    <AppGuard>
      <ConnectedRouter history={history}>
        <Loader>
          <AppRoutes />
        </Loader>
      </ConnectedRouter>
    </AppGuard>
  </Provider>
);

export default App;
