import { createSelector } from 'reselect';
import { AppState } from 'store/rootReducer';
import StepDTO from 'types/StepDTO';
import { StepsState } from './types';

/**
 * Возвращает список шагов.
 */
export const getSteps = createSelector<AppState, StepsState, StepDTO[]>(
  state => state.steps,
  steps => Object.values(steps) as StepDTO[]
);
