import { createReducer } from 'typesafe-actions';
import { StepsState, StepsAction } from './types';
import { fetchStepsAsync } from './actions';

export const initialState: StepsState = {};

export default createReducer<StepsState, StepsAction>(
  initialState
).handleAction(fetchStepsAsync.success, (state, { payload }) =>
  payload.reduce((steps, step) => ({ ...steps, [step.id]: step }), {})
);
