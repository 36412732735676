import React from 'react';
import { FieldMetaState } from 'react-final-form';
import clsx from 'clsx';

import './input.scss';

type Props = React.ComponentProps<'input'> & {
  meta?: FieldMetaState<string>;
  // any :c
  control?: any;
  [controlProp: string]: any;
};

const Input: React.FC<Props> = ({
  className,
  meta,
  control: Control = 'input',
  ...restProps
}) => {
  const error = meta && meta.touched && meta.error;
  return (
    <div
      className={clsx('input', className, {
        'input--error': error
      })}
    >
      <Control type="text" className="input__control" {...restProps} />
      {error && <div className="input__error">{error}</div>}
    </div>
  );
};

export default Input;
