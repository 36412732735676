import { createSelector } from 'reselect';
import { AppState } from 'store/rootReducer';
import SubjectDTO from 'types/SubjectDTO';
import { SubjectsState } from './types';

/**
 * Возвращает список тем.
 */
export const getSubjects = createSelector<
  AppState,
  SubjectsState,
  SubjectDTO[]
>(
  state => state.subjects,
  subjects => Object.values(subjects) as SubjectDTO[]
);
