import React from 'react';
import { Switch, Route } from 'react-router';
import IndexPage from 'pages/IndexPage';
import StepPage from 'pages/StepPage';
import RetainerPage from 'pages/RetainerPage';
import ContactPage from 'pages/ContactPage';
import SubjectPage from 'pages/SubjectPage';
import CategoryPage from 'pages/CategoryPage';
import TargetPage from 'pages/TargetPage';

const AppRoutes: React.FC = () => (
  <Switch>
    <Route exact path="/" component={IndexPage} />
    <Route exact path="/retainer" component={RetainerPage} />
    <Route exact path="/contact" component={ContactPage} />
    <Route exact path="/category/:id" component={CategoryPage} />
    <Route exact path="/subjects/:id" component={SubjectPage} />
    <Route exact path="/:step" component={StepPage} />
    <Route exact path="/:step/:target" component={TargetPage} />
  </Switch>
);

export default AppRoutes;
