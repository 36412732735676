import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import Steps from 'components/Steps';
import RetainerButton from 'components/RetainerButton';
import Logo from 'components/Logo';
import { useWindowSize, useToggle } from 'react-use';
import { TABLET_WIDTH } from 'constants/mq';

import './header.scss';
import { ReactComponent as MenuIcon } from './assets/menu.svg';
import { ReactComponent as CloseIcon } from './assets/close.svg';

type Props = React.ComponentProps<'header'>;

const Header: React.FC<Props> = ({ className, ...restProps }) => {
  const [menu, toggleMenu] = useToggle(false);
  const size = useWindowSize();

  return (
    <header
      className={clsx(
        'header',
        menu && size.width <= TABLET_WIDTH && 'header--menu',
        className
      )}
      {...restProps}
    >
      {size.width >= TABLET_WIDTH ? (
        <>
          <Link className="header__logo-link" to="/">
            <Logo className="header__logo" />
          </Link>
          <Steps className="header__steps" />
          <RetainerButton className="header__retainer" />
        </>
      ) : (
        <>
          <div className="header__row">
            <Link className="header__logo-link" to="/">
              <Logo className="header__logo" />
            </Link>
            {!menu && (
              <button
                onClick={toggleMenu}
                className="header__menu-open"
                type="button"
              >
                <MenuIcon className="header__menu-icon" />
              </button>
            )}
          </div>
          {menu && (
            <div className="header__menu">
              <Steps onClick={toggleMenu} className="header__menu-steps" />
              <RetainerButton className="header__menu-retainer" />
              <button
                type="button"
                onClick={toggleMenu}
                className="header__menu-close"
              >
                <CloseIcon className="header__menu-close-icon" />
              </button>
            </div>
          )}
        </>
      )}
    </header>
  );
};

export default Header;
