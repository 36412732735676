import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter, RouterState } from 'connected-react-router';
import steps from './steps/reducer';
import subjects from './subjects/reducer';
import locale from './locale/reducer';
import retainers from './retainers/reducer';

import { StepsState } from './steps/types';
import { SubjectsState } from './subjects/types';
import { LocaleState } from './locale/types';
import { RetainersState } from './retainers/types';

export type AppState = {
  router: RouterState;
  steps: StepsState;
  subjects: SubjectsState;
  locale: LocaleState;
  retainers: RetainersState;
};

export default function rootReducer(history: History) {
  return combineReducers<AppState>({
    router: connectRouter(history),
    steps,
    subjects,
    locale,
    retainers
  });
}
