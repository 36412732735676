import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { createAsyncAction } from 'typesafe-actions';
import * as api from 'services/api';
import RetainerDTO from 'types/RetainerDTO';

export const fetchRetainersAsync = createAsyncAction(
  'FETCH_RETAINERS_REQUEST',
  'FETCH_RETAINERS_SUCCESS',
  'FETCH_RETAINERS_FAILURE'
)<void, RetainerDTO[], void>();

export const fetchRetainers = (
  locale: string
): ThunkAction<Promise<any>, {}, {}, AnyAction> => async dispatch => {
  try {
    dispatch(fetchRetainersAsync.request());
    const retainers = await api.getRetainers(locale);
    dispatch(fetchRetainersAsync.success(retainers));
  } catch (error) {
    console.error(error);
    dispatch(fetchRetainersAsync.failure());
  }
};
