import React from 'react';
import { withTypes, Field } from 'react-final-form';
import clsx from 'clsx';
import TextareaAutosize from 'react-textarea-autosize';
import Input from 'components/Input';
import Button from 'components/Button';
import * as validate from 'utils/validate';

import './rate-form.scss';

export type RateFormValues = {
  name: string;
  comment: string;
  contact: string;
};

type Props = {
  onSubmit: (values: RateFormValues) => any;
  className?: string;
  initialValues?: RateFormValues;
  disabled?: boolean;
};

const { Form } = withTypes<RateFormValues>();

const RateForm: React.FC<Props> = ({
  className,
  onSubmit,
  initialValues,
  disabled
}) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, invalid }) => (
        <form className={clsx('rate-form', className)} onSubmit={handleSubmit}>
          <div className="rate-form__row">
            <div className="rate-form__field">
              <label htmlFor="name" className="rate-form__label">
                What's your name
              </label>
              <Field
                id="name"
                name="name"
                validate={validate.required}
                render={({ input, ...restProps }) => (
                  <Input {...input} {...restProps} />
                )}
              />
            </div>
            <div className="rate-form__field">
              <label htmlFor="contact" className="rate-form__label">
                Email or Phone
              </label>
              <Field
                id="contact"
                name="contact"
                validate={validate.required}
                render={({ input, ...restProps }) => (
                  <Input {...input} {...restProps} />
                )}
              />
            </div>
          </div>
          <div className="rate-form__row">
            <div className="rate-form__field">
              <label htmlFor="comment" className="rate-form__label">
                Project description
              </label>
              <Field
                id="comment"
                name="comment"
                render={({ input, ...restProps }) => (
                  <Input
                    maxRows={3}
                    control={TextareaAutosize}
                    {...input}
                    {...restProps}
                  />
                )}
              />
            </div>
          </div>
          <div className="rate-form__submit">
            <Button
              disabled={disabled || invalid}
              type="submit"
              className="rate-form__submit-button"
            >
              Rate
            </Button>
          </div>
        </form>
      )}
    />
  );
};

export default RateForm;
