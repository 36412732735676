import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { TABLET_WIDTH } from 'constants/mq';

import RetainerButton from 'components/RetainerButton';
import Steps from 'components/Steps';
import Header from 'components/Header';
import Footer from 'components/Footer';

import { ReactComponent as BackIcon } from './assets/back.svg';
import './page.scss';

type Props = {
  contentClassName?: string;
  title?: string;
  mode?: 'dark' | 'light';
  theme?: 'red';
  backPath?: string;
  footerProps?: React.ComponentProps<typeof Footer>;
} & React.ComponentProps<'div'> &
  RouteComponentProps;

const Page: React.FC<Props> = ({
  mode = 'light',
  theme,
  className,
  children,
  contentClassName,
  title,
  backPath,
  footerProps,
  history,
  location,
  staticContext,
  match,
  ...restProps
}) => {
  const handleBack = useCallback(
    () => (window.history.state ? history.goBack() : history.push(backPath!)),
    [backPath, history]
  );

  const size = useWindowSize();

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--vh',
      `${size.height * 0.01}px`
    );
  }, [size.height]);

  return (
    <div
      className={clsx(
        'page',
        `page--${mode}`,
        theme && `page--${theme}`,
        className
      )}
      {...restProps}
    >
      <Header className="page__header" />
      <div className="page__tools">
        {backPath && (
          <button onClick={handleBack} className="page__back" type="button">
            <BackIcon className="page__back-icon" />
            Back
          </button>
        )}
        {!backPath && size.width < TABLET_WIDTH && <Steps />}
        {size.width < TABLET_WIDTH && <RetainerButton />}
      </div>

      <div className={clsx('page__content', contentClassName)}>
        {title && <div className="page__title">{title}</div>}
        {children}
      </div>
      <Footer className="page__footer" {...footerProps} />
    </div>
  );
};

export default withRouter(Page);
