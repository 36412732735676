import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { getSteps } from 'store/steps/selectors';
import './steps.scss';

type Props = {
  onClick?: () => any;
} & React.ComponentProps<'div'>;

const Steps: React.FC<Props> = ({ className, onClick, ...restProps }) => {
  const steps = useSelector(getSteps);

  return (
    <div className={clsx('steps', className)} {...restProps}>
      {steps.map((step, i) => (
        <React.Fragment key={step.id}>
          <NavLink
            to={`/${step.name}`}
            activeClassName="steps__item--active"
            className="steps__item"
            type="button"
            onClick={onClick}
          >
            {step.name}
          </NavLink>
          {i + 1 < steps.length && <div className="steps__divider" />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Steps;
