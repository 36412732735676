import React, { useState, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import qs from 'query-string';
import * as api from 'services/api';
import Page from 'components/Page';
import CategoryDTO from 'types/CategoryDTO';
import CaseDTO from 'types/CaseDTO';

import './category-page.scss';

type Props = RouteComponentProps<{ id: string }>;

function useCategory(id: number) {
  const [category, setCategory] = useState<CategoryDTO | undefined>();
  useEffect(() => {
    api.getCategory(id).then(setCategory);
  }, [id]);
  return category;
}

function useCases(id: number) {
  const [cases, setCases] = useState<CaseDTO[]>([]);
  useEffect(() => {
    api.getCases(id).then(setCases);
  }, [id]);

  return cases;
}

const CategoryPage: React.FC<Props> = ({ match, location }) => {
  const category = useCategory(Number(match.params.id));
  const cases = useCases(Number(match.params.id));
  const query = useMemo(() => qs.parse(location.search) as { from?: string }, [
    location.search
  ]);

  return (
    <Page
      mode="dark"
      backPath="/"
      className="category-page"
      contentClassName="category-page__content"
      title={query.from}
    >
      <div className="category-page__container">
        {category && (
          <div className="category-page__title">{category.title}</div>
        )}
        <ul className="category-page__list">
          {cases.map(item => (
            <li key={item.case_id} className="category-page__list-item">
              <div className="category-page__case">
                <a
                  href={item.case_url}
                  target="blank"
                  className="category-page__case-image-link"
                >
                  <img
                    className="category-page__case-image"
                    src={item.case_img}
                    alt={item.case_name}
                  />
                </a>
                <div className="category-page__case-info">
                  <a
                    href={item.case_url}
                    target="blank"
                    className="category-page__case-name"
                  >
                    <u>{item.case_name}</u>
                  </a>
                  <div className="category-page__case-description">
                    {item.case_description}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Page>
  );
};

export default CategoryPage;
