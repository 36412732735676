import React, { useMemo } from 'react';

import './step-transition.scss';

type Props = {
  target?: HTMLAnchorElement;
  onEnd?: () => any;
};

const StepTransition: React.FC<Props> = ({ target, onEnd }) => {
  const style = useMemo<React.CSSProperties>(() => {
    if (target) {
      const { top, left, width, height } = target.getBoundingClientRect();
      const size = Math.max(window.innerWidth, window.innerHeight) * 2;

      return {
        top: top + height / 2,
        left: left + width / 2,
        width: size,
        height: size,
        visibility: 'visible'
      };
    } else {
      return {
        visibility: 'hidden'
      };
    }
  }, [target]);

  return (
    <div className="step-transition" style={style} onTransitionEnd={onEnd} />
  );
};

export default StepTransition;
