import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { getSteps } from 'store/steps/selectors';

const IndexPage: React.FC = () => {
  const steps = useSelector(getSteps);
  // Redirect to first step.
  return <Redirect to={`/${steps[0].name}`} />;
};

export default IndexPage;
