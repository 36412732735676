import React from 'react';
import clsx from 'clsx';
import './step-questions.scss';

type Props = {
  questions: string[];
} & React.ComponentProps<'div'>;

const StepQuestions: React.FC<Props> = ({
  className,
  questions,
  ...restProps
}) => {
  return (
    <div className={clsx('step-questions', className)} {...restProps}>
      {questions.map((question, key) => (
        <div className="step-questions__question" key={key}>
          {question}
        </div>
      ))}
    </div>
  );
};

export default StepQuestions;
