import React, { useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as api from 'services/api';

import Page from 'components/Page';

import SubjectCategoryDTO from 'types/SubjectCategoryDTO';

import { getSubjects } from 'store/subjects/selectors';

import './target-page.scss';

type Props = RouteComponentProps<{ target: string }>;

function useCategories(target: string) {
  const [categories, setCategories] = useState<SubjectCategoryDTO[]>([]);

  useEffect(() => {
    api.getSubjectsCategories(target).then(setCategories);
  }, [target]);

  return categories;
}

const TargetPage: React.FC<Props> = ({ match }) => {
  const subjects = useSelector(getSubjects);
  const categories = useCategories(match.params.target);
  const subjectsWithCategories = useMemo(
    () =>
      subjects
        .map(subject => ({
          ...subject,
          categories: categories.filter(
            category => category.subjects_name === subject.name
          )
        }))
        .filter(subject => subject.categories.length > 0),
    [categories, subjects]
  );

  return (
    <Page
      mode="dark"
      theme="red"
      backPath="/"
      className="target-page"
      contentClassName="target-page__content"
      title={match.params.target}
    >
      <div className="target-page__container">
        {subjectsWithCategories.map(subject => (
          <div key={subject.id} className="target-page__subject">
            <Link
              to={`/subjects/${subject.id}`}
              className="target-page__subject-name"
            >
              {subject.name}
            </Link>
            <ul className="target-page__subject-categories">
              {subject.categories.map(category => (
                <li
                  className="target-page__subject-category"
                  key={category.category_id}
                >
                  <Link
                    className="target-page__subject-category-link"
                    to={`/category/${category.category_id}?from=${category.subjects_name}`}
                  >
                    {category.category_title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </Page>
  );
};

export default TargetPage;
