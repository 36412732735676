import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useWindowSize } from 'react-use';

import Page from 'components/Page';
import Button from 'components/Button';

import { AppState } from 'store/rootReducer';
import { fetchRetainers } from 'store/retainers/actions';

import { DESKTOP_WIDTH, TABLET_WIDTH } from 'constants/mq';

import './retainer-page.scss';

const RateLink: React.FC = () => (
  <Link to="/contact">
    <Button className="retainer-page__action">Rate my task</Button>
  </Link>
);

const RetainerPage: React.FC = () => {
  const dispatch = useDispatch();
  const locale = useSelector((state: AppState) => state.locale);
  const retainers = useSelector((state: AppState) => state.retainers);
  const size = useWindowSize();
  const footerProps = useMemo(
    () => ({
      action: size.width >= DESKTOP_WIDTH ? <RateLink /> : null
    }),
    [size.width]
  );

  useEffect(() => {
    dispatch(fetchRetainers(locale));
  }, [dispatch, locale]);

  return (
    <Page
      backPath="/"
      className="retainer-page"
      contentClassName="retainer-page__content"
      title="Retainer"
      footerProps={footerProps}
    >
      <div className="retainer-page__retainers">
        {retainers.map(retainer => (
          <div className="retainer-page__retainer" key={retainer.id}>
            <div className="retainer-page__retainer-rate">{retainer.rate}</div>
            <div className="retainer-page__retainer-hours">
              {retainer.hours}
            </div>
          </div>
        ))}
      </div>
      <div className="retainer-page__description">
        {size.width <= TABLET_WIDTH
          ? '50% prepayment\n50% after works finished'
          : '50/50 payment'}
      </div>
      {size.width <= DESKTOP_WIDTH && (
        <div className="retainer-page__rate">
          <RateLink />
        </div>
      )}
    </Page>
  );
};

export default RetainerPage;
