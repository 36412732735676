import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { createAsyncAction } from 'typesafe-actions';
import * as api from 'services/api';
import SubjectDTO from 'types/SubjectDTO';

export const fetchSubjectsAsync = createAsyncAction(
  'FETCH_SUBJECTS_REQUEST',
  'FETCH_SUBJECTS_SUCCESS',
  'FETCH_SUBJECTS_FAILURE'
)<void, SubjectDTO[], void>();

export const fetchSubjects = (): ThunkAction<
  Promise<any>,
  {},
  {},
  AnyAction
> => async dispatch => {
  try {
    dispatch(fetchSubjectsAsync.request());
    const subjects = await api.getSubjects();
    dispatch(fetchSubjectsAsync.success(subjects));
  } catch (error) {
    console.error(error);
    dispatch(fetchSubjectsAsync.failure());
  }
};
