import StepDTO from 'types/StepDTO';

export const getTextParts = (
  text: StepDTO['text']
): Array<string | { url: string; text: string }> =>
  text.split(/[[\]]/g).map(part => {
    try {
      return JSON.parse(part);
    } catch (e) {
      return part;
    }
  });

export const getQuestions = (question: StepDTO['question']) =>
  question.split('\\n').map(q => q.trim());
