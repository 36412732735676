import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import thunkMiddleware from 'redux-thunk';
import rootReducer, { AppState } from './rootReducer';

export const history = createBrowserHistory();

export default function configureStore(initialState?: AppState) {
  return createStore(
    rootReducer(history),
    initialState,
    composeWithDevTools(
      applyMiddleware(routerMiddleware(history), thunkMiddleware)
    )
  );
}
