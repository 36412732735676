import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import Page from 'components/Page';

import * as api from 'services/api';

import { AppState } from 'store/rootReducer';
import { getSteps } from 'store/steps/selectors';

import StepCategoryDTO from 'types/StepCategoryDTO';

import './subject-page.scss';

type Props = RouteComponentProps<{ id: string }>;

function useCategories(subjectId: number) {
  const [categories, setCategories] = useState<StepCategoryDTO[]>([]);
  useEffect(() => {
    api.getStepsCategories(subjectId).then(setCategories);
  }, [subjectId]);
  return categories;
}

const SubjectPage: React.FC<Props> = ({ match }) => {
  const subject = useSelector(
    (state: AppState) => state.subjects[match.params.id]
  );
  const steps = useSelector(getSteps);
  const categories = useCategories(Number(match.params.id));
  const stepsWithCategories = useMemo(
    () =>
      steps
        .map(step => ({
          ...step,
          categories: categories.filter(
            category => category.step_name === step.name
          )
        }))
        .filter(step => step.categories.length > 0),

    [categories, steps]
  );

  // 404 may be?
  if (!subject) return null;

  return (
    <Page
      mode="light"
      className="subject-page"
      contentClassName="subject-page__content"
      backPath="/"
      title={subject.name}
    >
      <div className="subject-page__container">
        <div className="subject-page__description">{subject.description}</div>
        <div className="subject-page__steps">
          {stepsWithCategories.map(step => (
            <div key={step.id} className="subject-page__step">
              <Link to={`/${step.name}`} className="subject-page__step-name">
                <div>{step.name}</div>
              </Link>
              <ul className="subject-page__step-categories">
                {step.categories.map(category => (
                  <li
                    className="subject-page__step-category"
                    key={category.category_id}
                  >
                    <Link
                      className="subject-page__step-category-link"
                      to={`/category/${category.category_id}?from=${subject.name}`}
                    >
                      {category.category_title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </Page>
  );
};

export default SubjectPage;
