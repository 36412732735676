import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSteps } from 'store/steps/selectors';
import { fetchSteps } from 'store/steps/actions';
import { getSubjects } from 'store/subjects/selectors';
import { fetchSubjects } from 'store/subjects/actions';

const AppGuard: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const steps = useSelector(getSteps);
  const subjects = useSelector(getSubjects);

  useEffect(() => {
    if (!steps.length) {
      dispatch(fetchSteps());
    }
  }, [dispatch, steps.length]);

  useEffect(() => {
    if (!subjects.length) {
      dispatch(fetchSubjects());
    }
  }, [dispatch, subjects.length]);

  const isAvailable = Boolean(steps.length && subjects.length);

  return <>{isAvailable && children}</>;
};

export default AppGuard;
