import React from 'react';
import { NavLink } from 'react-router-dom';
import StepDTO from 'types/StepDTO';
import { getTextParts } from 'services/steps';
import clsx from 'clsx';
import './step-text.scss';

type Props = {
  step: StepDTO;
  onLink?: (event: React.MouseEvent<HTMLAnchorElement>) => any;
} & React.ComponentProps<'div'>;

const StepText: React.FC<Props> = ({
  className,
  step,
  onLink,
  ...restProps
}) => (
  <div className={clsx('step-text', className)} {...restProps}>
    {getTextParts(step.text).map((part, index) =>
      typeof part === 'string' ? (
        part
      ) : (
        <NavLink
          key={index}
          className="step-text__link"
          to={`/${step.name}/${part.url}`}
          onClick={onLink}
        >
          {part.text}
        </NavLink>
      )
    )}
  </div>
);

export default StepText;
