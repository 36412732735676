import React, {
  useMemo,
  useEffect,
  useCallback,
  useState,
  useContext
} from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import clsx from 'clsx';

import Page from 'components/Page';
import StepText from 'components/StepText';
import StepQuestions from 'components/StepQuestions';
import StepTransition from 'components/StepTransition';
import { LoaderContext } from 'components/Loader/Loader';

import { getSteps } from 'store/steps/selectors';

import { getQuestions } from 'services/steps';
import { TABLET_WIDTH } from 'constants/mq';

import './step-page.scss';

type Props = RouteComponentProps<{ step: string }>;

const StepPage: React.FC<Props> = ({ match, history }) => {
  const steps = useSelector(getSteps);
  const step = useMemo(
    () => steps.find(step => step.name === match.params.step),
    [match.params.step, steps]
  );
  const [transitionTarget, setTransitionTarget] = useState<
    HTMLAnchorElement | undefined
  >();
  const loaded = useContext(LoaderContext);

  const handleLink = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setTransitionTarget(event.currentTarget);
    },
    []
  );

  const handleLinkTransition = useCallback(() => {
    transitionTarget && history.push(transitionTarget.pathname);
    setTransitionTarget(undefined);
  }, [history, transitionTarget]);

  useEffect(() => {
    const container = document.querySelector('.step-page__questions');
    const elements: HTMLDivElement[] = container
      ? (Array.from(container.childNodes) as HTMLDivElement[])
      : [];

    elements.forEach(element => {
      element.setAttribute('data-x', String(0));
      element.setAttribute('data-z', String(Math.random() * -120 - 60));
      element.setAttribute('data-speed', String(Math.random() * 2 + 0.1 - 1));
    });

    let frame = requestAnimationFrame(animate);

    function animate() {
      elements.forEach(element => {
        const x = Number(element.dataset.x);
        const z = Number(element.dataset.z);
        const speed = Number(element.dataset.speed);
        const newX = x + speed;
        element.style.transform = `translateX(${newX}px) translateZ(${z}px)`;
        element.setAttribute('data-x', String(newX));

        const rect = element.getBoundingClientRect();

        if (
          (rect.left < 0 && speed < 0) ||
          (rect.right > window.innerWidth && speed > 0)
        ) {
          element.setAttribute('data-speed', String(-speed));
        }
      });
    }

    return () => cancelAnimationFrame(frame);
  }, []);

  useEffect(() => {
    const handle = (event: MouseEvent) => {
      const contentPage = document.querySelector(
        '.step-page__content'
      ) as HTMLDivElement;

      if (contentPage) {
        const { clientX: x, clientY: y } = event;
        const { innerWidth: w, innerHeight: h } = window;
        contentPage.style.transform =
          w < TABLET_WIDTH
            ? ''
            : [
                `perspective(${w * 2}px)`,
                `rotateY(${((x - w / 2) / w) * 20}deg)`,
                `rotateX(${(-(y - h / 2) / w) * 20}deg)`
              ].join(' ');
      }
    };

    window.addEventListener('mousemove', handle);
    return () => window.removeEventListener('mousemove', handle);
  });

  // 404 may be?
  if (!step) return null;

  return (
    <>
      <Page
        mode="dark"
        className="step-page"
        contentClassName="step-page__content"
      >
        <StepText
          className={clsx(
            'step-page__text',
            loaded && 'step-page__text--loaded'
          )}
          step={step}
          key={step.id}
          onLink={handleLink}
        />
        <StepQuestions
          className="step-page__questions"
          questions={getQuestions(step.question)}
        />
      </Page>
      <StepTransition target={transitionTarget} onEnd={handleLinkTransition} />
    </>
  );
};

export default StepPage;
